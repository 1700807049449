<template>
	<div id="app">
		<ScreenData />
	</div>
</template>

<script>
	import ScreenData from './components/ScreenData.vue'

	export default {
		name: 'App',
		components: {
			ScreenData
		}
	}
</script>

<style>
	body {
		margin: 0px;
		overflow-x: hidden;
	}

	#app {
		font-family: Avenir, Helvetica, Arial, sans-serif;
		-webkit-font-smoothing: antialiased;
		-moz-osx-font-smoothing: grayscale;
		color: #2c3e50;
	}
</style>