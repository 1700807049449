<template>

	<div class="main">
		<!--头部导航 GO-->
		<div class="titbar">
			<div class="title">归零微博应用数据分析</div>
			<div class="menus">
				<div class="menu-item">导航1</div>
				<div class="menu-item">导航2</div>
				<div class="menu-item">导航3</div>
				<div class="menu-item">导航4</div>
				<div class="menu-item">导航5</div>
				<div class="menu-item">导航6</div>
			</div>
			<div class="time">{{nowTime }}</div>
		</div>

		<!--主内容区 GO-->
		<section class="maincont">
			<!--左区域 GO-->
			<section class="cont-left">
				<!--左1 GO-->
				<div class="area area1">
					<div class="all-tit">
						自定义模块标题
					</div>

					<div class="base-data">
						<div class="data">
							<div class="num">9999</div>
							<div class="tit">销售总数(1)</div>
						</div>
						<div class="data">
							<div class="num">567</div>
							<div class="tit">数据展示(2)</div>
						</div>
						<div class="data">
							<div class="num">56345</div>
							<div class="tit">数据展示(3)</div>
						</div>
						<div class="data">
							<div class="num">721</div>
							<div class="tit">数据展示(4)</div>
						</div>
					</div>
				</div>

				<!--左2 GO-->
				<div class="area area2">
					<div class="all-tit">
						自定义模块标题
					</div>
					<div class="chart" id="chart2"></div>
				</div>

				<!--左3 GO-->
				<div class="area area3">
					<div class="all-tit">
						自定义模块标题
					</div>
					<div class="chart" id="chart3"></div>
				</div>

			</section>
			<!--中区域 GO-->
			<section class="cont-center">
				<div class="area area4">
					<div class="map-box">
						<div class="mymap" id="mymap"></div>
					</div>
				</div>

				<div class="area area5">
					<div class="all-tit">自定义模块标题</div>
					<div class="chart" id="chart5"></div>
				</div>
			</section>
			<!--右区域 GO-->
			<section class="cont-right">
				<!--右1 GO-->
				<div class="area area6">
					<div class="all-tit">
						自定义模块标题
					</div>
					<div class="chart" id="chart6"></div>
				</div>

				<!--右2 GO-->
				<div class="area area7">
					<div class="item">
						<div class="all-tit">
							自定义模块标题
						</div>
						<div class="chart" id="chart7"></div>
					</div>
				</div>
			</section>
		</section>

	</div>
</template>


<script>
	import * as echarts from "echarts";
	// 显示中国地图
	import '../utils/map/js/china.js'

	export default {
		name: 'ScreenData',
		props: {
			datas: Array,
			defalut: () => {}
		},
		data() {
			return {
				nowTime: null,
				timer: null,
				dataList: [],
				geoCoordMap: {}
			}
		},
		mounted() {
			//方法的数字同区块（area）数字对应！！！

			this.handleChart2(); //左1：折线
			this.handleChart3(); //左2：柱状
			this.handleChart4(); //中1：地图
			this.handleChart5(); //中2：饼图
			this.handleChart6(); //右1：饼图
			this.handleChart7(); //右2：饼图

			this.getNowTime();

		},
		methods: {

			//日期处理
			getNowTime() {
				let speed = 1000
				let that = this
				let theNowTime = function() {
					that.nowTime = that.timeNumber()
				}
				setInterval(theNowTime, speed)
			},
			timeNumber() {
				let today = new Date()
				let date = today.getFullYear() + '-' + this.twoDigits(today.getMonth() + 1) + '-' + this.twoDigits(today
					.getDate())
				let time = this.twoDigits(today.getHours()) + ':' + this.twoDigits(today.getMinutes()) + ':' + this
					.twoDigits(today.getSeconds())
				return date + '  ' + time
			},
			twoDigits(val) {
				if (val < 10) return '0' + val
				return val
			},

			//左1
			handleChart2() {
				var setChart = echarts.init(document.getElementById('chart2'));
				let option = {
					legend: {
						orient: 'vertical',
						itemWidth: 10,
						itemHeight: 10,
						textStyle: {
							color: 'rgba(255,255,255,.5)'
						},
						top: '20%',
						right: 30,
						data: ['第1组', '第2组', '第3组', '第4组']
					},
					color: ['#37a2da', '#32c5e9', '#9fe6b8', '#ffdb5c', '#ff9f7f', '#fb7293', '#e7bcf3', '#8378ea'],
					tooltip: {
						trigger: 'item',
						formatter: "{b} : {c} ({d}%)"
					},

					calculable: true,
					series: [{

						type: 'pie',
						radius: [20, 70],
						center: ["35%", "50%"],
						roseType: 'area',
						data: [{
								value: 300,
								name: '第1组'
							},
							{
								value: 100,
								name: '第2组'
							},
							{
								value: 205,
								name: '第3组'
							},
							{
								value: 380,
								name: '第4组'
							},

						],
						label: {
							normal: {
								formatter: function(param) {
									return param.name + ':\n' + param.value + '\n';
								}

							}
						},
						labelLine: {
							normal: {
								length: 5,
								length2: 15,
								lineStyle: {
									width: 1
								}
							}
						},
						itemStyle: {
							normal: {
								shadowBlur: 30,
								shadowColor: 'rgba(0, 0, 0, 0.4)'
							}

						},
					}]
				};

				setChart.setOption(option);
				window.addEventListener('resize', () => {
					setChart.resize();
				});
			},

			//左2
			handleChart3() {
				var setChart = echarts.init(document.getElementById('chart3'));
				let option = {
					grid: { //图表位置
						left: '20',
						top: '20',
						right: '30',
						bottom: '50',
						containLabel: true
					},
					tooltip: { //鼠标移入单个柱状
						trigger: 'axis',
						axisPointer: { // 坐标轴指示器，坐标轴触发有效
							type: 'shadow' // 默认为直线，可选为：'line' | 'shadow'
						}

					},

					xAxis: {
						type: 'category',
						data: ['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun'],
						axisTick: { //坐标轴 刻度
							show: true, //是否显示
						},
						axisLine: { //标轴 轴线
							show: true, //是否显示
							lineStyle: {
								color: 'rgba(255,255,255,.1)',
								width: 1,
								type: 'dotted',
							},

						},

						axisLabel: { //X轴文字
							textStyle: {
								fontSize: 12,
								color: '#fff'
							},
						},
					},

					yAxis: {
						type: 'value',
						splitLine: { //分割线
							show: true,
							lineStyle: {
								color: 'rgba(255,255,255,.1)',
								width: 1,
								type: 'dotted'
							}
						},

						axisLabel: { //Y轴刻度值
							formatter: '{value}',
							textStyle: {
								fontSize: 12,
								color: '#fff'
							},
						},
						axisLine: { //坐标轴 轴线
							show: false, //是否显示
						},

					},
					series: [{
						type: 'bar',
						barWidth: 15,
						barGap: 1, //柱子之间间距 
						//柱图宽度  两种情况都要设置，设置series 中对应数据柱形的itemStyle属性下的emphasis和normal的barBorderRadius属性初始化时候圆角  鼠标移上去圆角
						itemStyle: {
							normal: {
								barBorderRadius: 50,
								color: "#5ac8ad",
							}

						},
						data: [120, 200, 150, 80, 70, 110, 130],
					}]
				};
				setChart.setOption(option);
				window.addEventListener('resize', () => {
					setChart.resize();
				});
			},


			//中1 中国地图
			handleChart4() {
				var setChart = echarts.init(document.getElementById('mymap'));
				// myChart.on("click", function (param) {
				//   that.areacode = param.name;
				//   that.shengcode = param.data.name;
				//   that.flag = 2;
				//   that.echartslt();
				// });
				var data = [{
						name: '河北',
						value: 9
					},
					{
						name: '山西',
						value: 112
					},
					{
						name: '辽宁',
						value: 12
					},
					{
						name: '吉林',
						value: 22
					},
					{
						name: '江苏',
						value: 84
					},
					{
						name: '浙江',
						value: 15
					},
					{
						name: '安徽',
						value: 16
					},
					{
						name: '福建',
						value: 68
					},
					{
						name: '江西',
						value: 18
					},
					{
						name: '山东',
						value: 19
					},
					{
						name: '河南',
						value: 91
					},
					{
						name: '湖北',
						value: 21
					},
					{
						name: '湖南',
						value: 21
					},
					{
						name: '广东',
						value: 22
					},
					{
						name: '海南',
						value: 23
					},
					{
						name: '四川',
						value: 34
					},
					{
						name: '贵州',
						value: 24
					},
					{
						name: '云南',
						value: 25
					},
					{
						name: '陕西',
						value: 25
					},
					{
						name: '青海',
						value: 75
					},
					{
						name: '台湾',
						value: 65
					},
					{
						name: '北京',
						value: 85
					},
					{
						name: '天津',
						value: 25
					},
					{
						name: '上海',
						value: 76
					},
					{
						name: '重庆',
						value: 66
					},
					{
						name: '内蒙古',
						value: 26
					},
					{
						name: '广西',
						value: 27
					},
					{
						name: '宁夏',
						value: 127
					},
					{
						name: '新疆',
						value: 177
					},
					{
						name: '香港',
						value: 28
					},
					{
						name: '澳门',
						value: 29
					},
					{
						name: '黑龙江',
						value: 69
					},
					{
						name: '西藏',
						value: 29
					},
					{
						name: '甘肃',
						value: 29
					},

				];


				var option = {
					title: {
						text: '地图主标题名称',
						left: 'center',
						top: '40',
						textStyle: {
							color: '#fff'
						},
						subtext: '地图副标题名称',
						subtextStyle: {
							color: '#fff'
						},
					},
					tooltip: {
						triggerOn: "mousemove",
						show: true,
						padding: 5,
						formatter: function(params) {
							return (
								params.name +
								"<br/>大熊猫数量：" +
								params.data["value"] +
								"<br/>人均GDP：" + 9999

							);

						},
					},

					visualMap: { //图例
						min: 0,
						max: 1000,
						left: 26,
						bottom: 50,
						showLabel:true,
						pieces: [{
								gt: 120,
								label: "120以上",
								color: "#11eabb",
							},
							{
								gt: 90,
								lt: 120,
								label: "90-120",
								color: "#04b08e",
							},
							{
								gt: 60,
								lt: 90,
								label: "60-90",
								color: "#237b90",
							},
							{
								gt: 30,
								lt: 60,
								label: "30-60",
								color: "#2b73b8",
							},
							{
								lt: 30,
								label: "30以下",
								color: "rgba(56,84,145,.8)",
							},
						],
						textStyle: {
							color: "#999",
						},
						show: !0,
					},
					geo: {
						map: "china",
						roam: !1,
						scaleLimit: {
							min: 1,
							max: 2.5,
						},
						zoom: 1.00, //地图缩放比例
						top: 70, //地图距离
						label: {
							normal: {
								show: !0,
								fontSize: "14",
								color: "#ebebeb", //一级行政区名称颜色
							},
						},
						itemStyle: {
							normal: {
								areaColor: 'rgba(2,37,101,.5)',
								borderColor: 'rgba(112,187,252,.5)'
							},
							emphasis: {
								areaColor: 'rgba(2,37,101,.8)',
								shadowOffsetX: 15,
								shadowOffsetY: 15,
								shadowBlur: 20,
								borderWidth: 0,
								shadowColor: "rgba(0, 0, 0, 0.5)",
							},
						},
					},
					layoutCenter: ["30%", "30%"],
					series: [{
						name: "数量",
						type: "map",
						geoIndex: 0,
						data: data,
						itemStyle: {
							normal: {
								color: '#ffffff'
							}
						}
					}, ],
				}
				setChart.setOption(option);
				window.addEventListener('resize', () => {
					setChart.resize();
				});
			},


			/**
			 * 中2图表
			 * */
			handleChart5() {
				var setChart = echarts.init(document.getElementById('chart5'));
				var option = {
					tooltip: {
						trigger: 'axis',
						axisPointer: {
							lineStyle: {
								color: '#dddc6b'
							}
						}
					},
					grid: {
						left: '10',
						top: '20',
						right: '30',
						bottom: '10',
						containLabel: true
					},

					xAxis: [{
						type: 'category',
						boundaryGap: false,
						axisLabel: {
							textStyle: {
								color: "rgba(255,255,255,.6)",
								fontSize: 14,
							},
						},
						axisLine: {
							lineStyle: {
								color: 'rgba(255,255,255,.2)'
							}

						},

						data: ['1月', '2月', '3月', '4月', '5月', '6月', '7月', '8月', '9月', '10月', '11月', '12月']

					}, {

						axisPointer: {
							show: false
						},
						axisLine: {
							show: false
						},
						position: 'bottom',
						offset: 20,



					}],

					yAxis: [{
						type: 'value',
						axisTick: {
							show: false
						},
						splitNumber: 4,
						axisLine: {
							lineStyle: {
								color: 'rgba(255,255,255,.1)'
							}
						},
						axisLabel: {
							textStyle: {
								color: "rgba(255,255,255,.6)",
								fontSize: 16,
							},
						},

						splitLine: {
							lineStyle: {
								color: 'rgba(255,255,255,.1)',
								type: 'dotted',
							}
						}
					}],
					series: [{
							name: '结算率',
							type: 'line',
							smooth: true,
							symbol: 'circle',
							symbolSize: 5,
							showSymbol: false,
							lineStyle: {
								normal: {
									color: 'rgba(31, 174, 234, 1)',
									width: 2
								}
							},
							areaStyle: {
								normal: {
									color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [{
										offset: 0,
										color: 'rgba(31, 174, 234, 0.4)'
									}, {
										offset: 0.8,
										color: 'rgba(31, 174, 234, 0.1)'
									}], false),
									shadowColor: 'rgba(0, 0, 0, 0.1)',
								}
							},
							itemStyle: {
								normal: {
									color: '#1f7eea',
									borderColor: 'rgba(31, 174, 234, .1)',
									borderWidth: 5
								}
							},
							data: [3, 6, 3, 6, 3, 9, 3, 12, 6, 8, 3, 5, 9, 3]

						},

					]

				};
				setChart.setOption(option);
				window.addEventListener('resize', () => {
					setChart.resize();

				});

			},


			/**
			 * 右1图表
			 * */
			handleChart6() {
				var setChart = echarts.init(document.getElementById('chart6'));
				var option = {

					tooltip: {
						trigger: 'axis'
					},
					radar: [{
						indicator: [{
							text: '标题1',
							max: 100
						}, {
							text: '标题2',
							max: 100
						}, {
							text: '标题3',
							max: 100
						}, {
							text: '标题4',
							max: 100
						}, {
							text: '标题5',
							max: 100
						}],
						textStyle: {
							color: 'red'
						},
						center: ['50%', '50%'],
						radius: '70%',
						startAngle: 90,
						splitNumber: 4,
						shape: 'circle',

						name: {
							padding: -5,
							formatter: '{value}',
							textStyle: {

								color: 'rgba(255,255,255,.5)'
							}
						},
						splitArea: {
							areaStyle: {
								color: 'rgba(255,255,255,.05)'
							}
						},
						axisLine: {
							lineStyle: {
								color: 'rgba(255,255,255,.05)'
							}
						},
						splitLine: {
							lineStyle: {
								color: 'rgba(255,255,255,.05)'
							}
						}
					}, ],
					series: [{
						name: '雷达图',
						type: 'radar',
						tooltip: {
							trigger: 'item'
						},
						data: [{
							name: '雷达图1标题',
							value: [90, 10, 30, 30, 80, ],
							lineStyle: {
								normal: {
									color: '#03b48e',
									width: 2,
								}
							},
							areaStyle: {
								normal: {
									color: '#03b48e',
									opacity: .4
								}
							},
							symbolSize: 0,

						}, {
							name: '雷达图2标题',
							value: [30, 20, 75, 80, 70],
							symbolSize: 0,
							lineStyle: {
								normal: {
									color: '#3893e5',
									width: 2,
								}
							},
							areaStyle: {
								normal: {
									color: 'rgba(19, 173, 255, 0.5)'
								}
							}
						}]
					}, ]
				};

				setChart.setOption(option);
				window.addEventListener('resize', () => {
					setChart.resize();

				});

			},

			/**
			 * 右2图表
			 * */
			handleChart7() {
				var setChart = echarts.init(document.getElementById('chart7'));

				var data = [683, 234, 234, 23, 345, 320, 280, 71, 254, 229, 210, 190, 82, 234, 234, 523, 345, 333, 345,
					333]
				var titlename = ['名称1', '名称2', '名称3', '名称4', '名称5', '名称6', '名称7', '名称8', '名称9', '名称10', '名称11', '名称12',
					'名称13', '名称14', '名称15', '名称16', '名称17', '名称18', '名称19', '名称20'
				];
				var option = {
					grid: {
						left: '20',
						top: '0',
						right: '30',
						bottom: '30',
						containLabel: true
					},
					xAxis: {
						show: false
					},
					yAxis: [{
						show: true,
						data: titlename,
						inverse: true,
						axisLine: {
							show: false
						},
						splitLine: {
							show: false
						},
						axisTick: {
							show: false
						},
						axisLabel: {
							textStyle: {
								color: '#fff'
							},
						},

					}, {
						show: false,
						inverse: true,
						data: data,
						axisLabel: {
							textStyle: {
								color: '#fff'
							}
						},
						axisLine: {
							show: false
						},
						splitLine: {
							show: false
						},
						axisTick: {
							show: false
						},
					}],
					series: [{
						name: '条',
						type: 'bar',
						yAxisIndex: 0,
						data: data,
						barWidth: 15,
						itemStyle: {
							normal: {
								barBorderRadius: 50,
								color: '#1089E7',
							}
						},
						label: {
							normal: {
								show: true,
								position: 'right',
								formatter: '{c}',
								textStyle: {
									color: 'rgba(255,255,255,.5)'
								}
							}
						},
					}]
				};
				setChart.setOption(option);
				window.addEventListener('resize', () => {
					setChart.resize();

				});

			},


		}
	}
</script>

<style lang="scss" scoped>
	//公共标题
	.all-tit {
		width: calc(100% - 36px);
		color: #1ac9ff;
		font-size: 18px;
		position: relative;
		height: 40px;
		line-height: 40px;
		padding-left: 36px;
		border-bottom: 1px solid rgba(7, 118, 181, .5);

		&::before {
			width: 6px;
			height: 6px;
			background: #0091ff;
			border-radius: 50%;
			content: '';
			display: block;
			position: absolute;
			top: 50%;
			left: 20px;
			margin-top: -3px;
		}

	}

	//区块公共背景
	.area {
		border: 1px solid rgba(7, 118, 181, .5);
		box-shadow: inset 0 0 10px rgba(7, 118, 181, .4);
		margin-bottom: 12px;
		position: relative;
		overflow: hidden;
	}

	.main {
		width: 100vw;
		min-height: 100vh;
		background: #2d4b8b;
		overflow: hidden;
		position: relative;

		//头部标题 GO 
		.titbar {
			position: relative;
			height: 90px;
			margin: 0 15px;
			padding-right: 60px;

			.title {
				font-size: 30px;
				text-align: center;
				line-height: 90px;
				color: #b5f3ff;
				float: left;

			}

			//菜单 GO
			.menus {
				display: flex;
				height: 90px;
				line-height: 90px;
				padding-left: 60px;
				align-items: center;

				.menu-item {
					position: relative;
					height: 30px;
					padding: 0 20px;
					line-height: 30px;
					margin-right: 20px;
					cursor: pointer;
					color: #b5f3ff;

					&:before,
					&:after {
						content: '';
						width: 12px;
						display: block;
						height: 100%;
						position: absolute;
						top: 0px;
					}

					&:before {
						left: 0px;
						background: url('../assets/tit-left.png');
					}

					&:after {
						right: 0px;
						background: url('../assets/tit-right.png');
					}

					&:hover:before {
						background: url('../assets/tit-left-hover.png');
					}

					&:hover:after {
						background: url('../assets/tit-right-hover.png');
					}

					&:hover {
						color: #ff4a00;
					}
				}
			}

			.time {
				position: absolute;
				right: 30px;
				line-height: 90px;
				top: 0;
				color: #ffffff;
			}
		}

		.maincont {
			display: flex;
			overflow: hidden;

			.cont-left {
				width: calc(22% - 10px);
				margin-left: 10px;
				overflow: hidden;

				.area1 {
					.base-data {
						.data {
							float: left;
							width: 50%;
							padding: 10px 0;
							text-align: center;

							.num {
								width: 100px;
								height: 100px;
								margin: 0 auto 5px auto;
								position: relative;
								display: flex;
								justify-content: center;
								align-items: center;
								font-size: 30px;
								font-family: electronicFont;
								color: #fff32b;

								&::before {
									position: absolute;
									width: 100%;
									height: 100%;
									content: "";
									background: url("../assets/img1.png") center center;
									border-radius: 100px;
									background-size: 100% 100%;
									opacity: .3;
									left: 0;
									top: 0;
									animation: end 15s infinite linear;
								}

								&::after {
									position: absolute;
									width: 86%;
									background: url(../assets/img2.png) center center;
									border-radius: 100px;
									background-size: 100% 100%;
									opacity: .3;
									height: 86%;
									content: "";
									left: 7%;
									top: 7%;
									animation: start 15s infinite linear;

								}
							}

							.tit {
								font-size: 14px;
								color: #1ac9ff;
							}

						}

					}

				}

				.area2 {
					height: 300px;

					.chart {
						width: 100%;
						height: 300px;
					}
				}

				.area3 {
					height: 319px;

					.chart {
						width: 100%;
						height: 319px;
					}
				}
			}

			.cont-center {
				width: calc(56% - 20px);
				margin: 0 10px;
				overflow: hidden;

				.area4 {
					background: url(../assets/bg3.png) no-repeat center center;
					background-size: 100% 100%;

					.map-box {
						height: 670px;

						.mymap {
							width: 100%;
							height: 670px;
						}
					}

				}

				.area5 {
					width: 100%;
					height: 290px;

					.chart {
						width: 100%;
						height: 290px;
					}
				}
			}

			.cont-right {
				width: calc(22% - 10px);
				margin-right: 10px;
				overflow: hidden;



				.area6 {
					width: 100%;
					height: 300px;

					.chart {
						width: 100%;
						height: 300px;
					}
				}

				.area7 {
					width: 100%;
					height: 660px;

					.chart {
						width: 100%;
						height: 660px;
					}
				}
			}
		}

		.nav1 {
			margin-left: -6px;
			margin-right: -6px;
		}

		.nav1>li {
			padding: 0 6px;
			float: left;
		}

		.box:before {
			top: -1px;
		}

		.boxnav {
			padding: 10px;
		}


		.map {
			position: relative;
			height: 100%;
		}

		.map img {}

		.mapnav {
			position: absolute;
			z-index: 10;
		}



	}

	@keyframes start {
		to {
			transform: rotate(-360deg)
		}
	}

	@keyframes end {
		to {
			transform: rotate(360deg)
		}
	}
</style>